import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Form.css';
import UploadIcon from "../../../Image/UploadIcon.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Url } from "../../../function/FunctionApi";
import { Modal, Button } from 'react-bootstrap';

const createUrl = `${Url}OrderForSubscribe/create-order-subscribe`;
const updateUrl = `${Url}OrderForSubscribe/update`;

const Form = ({ userData, apiData }) => {
    const [formData, setFormData] = useState({
        ProjectType: '',
        OrderNumber: '', // رقم الطلب
        Contractor: '', // يمكن تعديل المقاول
        District: '',
        Station: '',
        Consultant: '',
        Note: '',
        safetyViolation: ''
    });

    const [fileData, setFileData] = useState({
        ModelPhotos: [],
        SitePhotos: [],
        SafetyWastePhotos: [],
    });

    const fileInputRefs = {
        ModelPhotos: useRef(null),
        SitePhotos: useRef(null),
        SafetyWastePhotos: useRef(null),
    };

    const [errorMessage, setErrorMessage] = useState('');
    const token = userData?.token;

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingArchive, setLoadingArchive] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (event, fileType) => {
        const uploadedFiles = Array.from(event.target.files);
        const newFiles = uploadedFiles.filter((file) => file.size <= 5 * 1024 * 1024);
        const rejectedFiles = uploadedFiles.filter((file) => file.size > 5 * 1024 * 1024);

        if (rejectedFiles.length) {
            setErrorMessage('بعض الملفات كانت كبيرة جدًا ولا يمكن رفعها.');
        } else {
            setErrorMessage('');
        }

        setFileData((prev) => ({
            ...prev,
            [fileType]: [...prev[fileType], ...newFiles],
        }));
    };

    const handleFileDelete = (fileType, fileName) => {
        setFileData((prev) => ({
            ...prev,
            [fileType]: prev[fileType].filter((file) => file.name !== fileName),
        }));
    };

    const openFileSelector = (fileType) => {
        fileInputRefs[fileType].current.click();
    };

    const validateForm = () => {
        const { Contractor, District, Station, Consultant } = formData;
        return Contractor && District && Station && Consultant; // التأكد من وجود البيانات المطلوبة
    };

    const handleSubmit = async (isArchive) => {
        if (!validateForm()) {
            setErrorMessage('يرجى ملء جميع الحقول المطلوبة.');
            setShowErrorModal(true);
            return;
        }

        const submissionData = new FormData();

        // Append OrderNumber regardless of apiData presence
        submissionData.append('OrderNumber', formData.OrderNumber);

        // If apiData is provided, append only modified fields
        if (apiData) { // Check if apiData is provided
            if (formData.ProjectType !== apiData.projectType) {
                submissionData.append('ProjectType', formData.ProjectType);
            }
            if (formData.Contractor !== apiData.contractor) {
                submissionData.append('Contractor', formData.Contractor);
            }
            if (formData.District !== apiData.district) {
                submissionData.append('District', formData.District);
            }
            if (formData.Station !== apiData.station) {
                submissionData.append('Station', formData.Station);
            }
            if (formData.Consultant !== apiData.consultant) {
                submissionData.append('Consultant', formData.Consultant);
            }
            if (formData.Note !== apiData.note) {
                submissionData.append('Note', formData.Note);
            }
            if (formData.safetyViolation !== apiData.isExist) {
                submissionData.append('safetyViolation', formData.safetyViolation);
            }
        } else {
            // For a new entry, append all required fields
            submissionData.append('ProjectType', formData.ProjectType);
            submissionData.append('Contractor', formData.Contractor);
            submissionData.append('District', formData.District);
            submissionData.append('Station', formData.Station);
            submissionData.append('Consultant', formData.Consultant);
            submissionData.append('Note', formData.Note);
            submissionData.append('safetyViolation', formData.safetyViolation);
        }

        // Add uploaded files
        Object.entries(fileData).forEach(([key, files]) => {
            files.forEach(file => {
                submissionData.append(key, file);
            });
        });

        // Add isArchive to submission data
        submissionData.append('isArchive', isArchive);

        if (isArchive) {
            setLoadingArchive(true);
        } else {
            setLoadingSubmit(true);
        }

        try {
            // Use POST when there is no apiData to create a new entry
            const response = apiData
                ? await axios.put(updateUrl, submissionData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                })
                : await axios.post(createUrl, submissionData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                });

            setShowSuccessModal(true);

            // Reset form data
            setFormData((prev) => ({
                ...prev,
                Contractor: '',
                Note: '',
                safetyViolation: ''
            }));
            setFileData({
                ModelPhotos: [],
                SitePhotos: [],
                SafetyWastePhotos: [],
            });

        } catch (error) {
            console.error('Error submitting form', error);
            const errorMessage = error.response?.data?.message || 'فشل إرسال النموذج. يرجى المحاولة مرة أخرى.';
            setErrorMessage(errorMessage);
            setShowErrorModal(true);
        } finally {
            setLoadingSubmit(false);
            setLoadingArchive(false);
        }
    };


    const renderUploadSection = (label, fileType, buttonLabel, multiple = true) => (
        <div className="upload-section">
            <h4>{label}</h4>
            <div className="upload-box">
                <span className="upload-icon">
                    <img src={UploadIcon} alt='Upload' />
                </span>
                <p>{buttonLabel}</p>
                <input
                    type="file"
                    onChange={(e) => handleFileChange(e, fileType)}
                    multiple={multiple}
                    ref={fileInputRefs[fileType]}
                    style={{ display: 'none' }}
                    accept=".jpg,.jpeg,.png,.pdf"
                />
                <div className="button-group">
                    <button type="button" className="upload-button" onClick={() => openFileSelector(fileType)}>رفع ملف</button>
                </div>
            </div>
            <div className="file-list">
                {fileData[fileType].map((file, index) => (
                    <div key={index} className="file-item">
                        <span>{file.name || "name Image"}</span>
                        <button className='delete' onClick={() => handleFileDelete(fileType, file.name)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

    useEffect(() => {
        if (apiData) {
            setFormData({
                ProjectType: apiData.projectType === 1 ? '1' : '2',
                OrderNumber: apiData.orderNumber || '', // حفظ رقم الطلب
                Contractor: apiData.contractor || '',
                District: apiData.district || '',
                Station: apiData.station || '',
                Consultant: apiData.consultant || '',
                Note: apiData.note || '',
                safetyViolation: apiData.isExist ? 'exists' : 'not-exists'
            });
            setFileData({
                ModelPhotos: apiData.modelPhotos || [],
                SitePhotos: apiData.sitePhotos || [],
                SafetyWastePhotos: apiData.safetyWastePhotos || [],
            });
        }
    }, [apiData]);

    return (
        <div className="form-container" dir='rtl'>
            <div className='radio-container'>
                <div className='container'>
                    <h2>قم بتحديد نوع المشروع</h2>
                    <div className="radio-group">
                        {['1', '2'].map((type) => (
                            <label key={type}>
                                <input
                                    type="radio"
                                    name="ProjectType"
                                    value={type}
                                    checked={formData.ProjectType === type}
                                    onChange={handleChange}
                                />
                                <span className="radio-circle" />
                                <span>{type === "1" ? 'تنفيذ شبكة' : 'عداد'}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>

            <div className='FormData'>
                <div className='container'>
                    <h3>بيانات الطلب</h3>
                    <p>Get a Quote Immediately Upon Form Submission</p>
                    <div className="input-group">
                        {['OrderNumber', 'Contractor'].map((field) => (
                            <input
                                key={field}
                                type="text"
                                name={field}
                                placeholder={field === 'OrderNumber' ? 'رقم الطلب' : 'المقاول'}
                                value={formData[field]}
                                onChange={handleChange}
                                required
                                readOnly={field === 'OrderNumber' && !!apiData} // رقم الطلب readonly إذا كانت apiData موجودة
                            />
                        ))}
                    </div>
                    <div className="input-group">
                        {['District', 'Station'].map((field) => (
                            <input
                                key={field}
                                type="text"
                                name={field}
                                placeholder={field === 'District' ? 'الحي' : 'المحطة'}
                                value={formData[field]}
                                onChange={handleChange}
                                required
                            />
                        ))}
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            name="Consultant"
                            placeholder="الاستشاري"
                            value={formData.Consultant}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    {renderUploadSection('النموزج', 'ModelPhotos', 'تصوير مستندات مع الختم')}
                    {renderUploadSection('صور الموقع', 'SitePhotos', 'صور الموقع')}

                    <div className="violations">
                        <h4>مخالفات السلامة</h4>
                        <div className="radio-group">
                            {['exists', 'not-exists'].map((value) => (
                                <label key={value}>
                                    <input
                                        type="radio"
                                        name="safetyViolation"
                                        value={value}
                                        checked={formData.safetyViolation === value}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-circle" />
                                    {value === 'exists' ? 'يوجد' : 'لا يوجد'}
                                </label>
                            ))}
                        </div>
                    </div>

                    {formData.safetyViolation === 'exists' && renderUploadSection('صور مخالفات السلامة', 'SafetyWastePhotos', 'صور المخالفات')}

                    <div className="input-group">
                        <textarea
                            name="Note"
                            placeholder="ملاحظات"
                            value={formData.Note}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="button-group">
                        <button type="button" className="submit-button" style={{ background: 'rgba(76, 175, 79, 1)', color: 'white' }}
                            onClick={() => handleSubmit(false)} disabled={loadingSubmit || loadingArchive}>
                            {loadingSubmit ? 'جاري التحميل...' : 'إرسال'}
                        </button>
                        <button type="button" className="archive-button" style={{ background: 'rgba(244, 67, 54, 1)', color: 'white' }} onClick={() => handleSubmit(true)} disabled={loadingArchive || loadingSubmit}>
                            {loadingArchive ? 'جاري التحميل...' : 'إرسال مع الأرشفة'}
                        </button>
                    </div>

                </div>
            </div>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>نجاح</Modal.Title>
                </Modal.Header>
                <Modal.Body>تم إرسال الطلب بنجاح.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Error Modal */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>خطأ</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
                        إغلاق
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Form;
