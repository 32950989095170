import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import UploadIcon from '../../Image/UploadIcon.png';

const UploadSection = ({ label, fileType, buttonLabel, multiple, handleFileChange, openFileSelector, fileData, handleFileDelete, fileInputRef }) => (
    <div className="upload-section">
        <h4>{label}</h4>
        <div className="upload-box">
            <span className="upload-icon">
                <img src={UploadIcon} alt='Upload' />
            </span>
            <p>{buttonLabel}</p>
            <input
                type="file"
                onChange={(e) => handleFileChange(e, fileType)}
                multiple={multiple}
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".jpg,.jpeg,.png,.pdf"
            />
            <div className="button-group">
                <button type="button" className="upload-button" onClick={() => openFileSelector(fileType)}>رفع ملف</button>
            </div>
        </div>
        <div className="file-list">
            {fileData[fileType].map((file, index) => (
                <div key={index} className="file-item">
                    <span>{file.name || "image name"}</span>
                    <button className='delete' onClick={() => handleFileDelete(fileType, file.name)}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                </div>
            ))}
        </div>
    </div>
);

export default UploadSection;
