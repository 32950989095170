import React,{useEffect} from "react";
import { Link, useParams } from "react-router-dom"; 
import "./Subscribers.css"; 
import img1 from "../../Image/Frame.png";
import img2 from "../../Image/Frame (1).png";
import img3 from "../../Image/Group (1).png";

function Subscribers() {
  const { name } = useParams(); 
  let cards = [];
  let title = ''; 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  switch (name) {
    case "Subscribers":
      cards = [
        { id: 1, image: img1, text: "اضافة طلب", link: "/submit-application" }, 
        { id: 2, image: img2, text: "الطلبات المؤرشفة", link: "/requests/Archived/Subscribers" },
        { id: 3, image: img3, text: "الطلبات المكتملة", link: "/requests/Completed/Subscribers" }, 
      ];
      title = "المشتركين"; 
      break;
    case "Operations-Maintenance":
      cards = [
        { id: 1, image: img1, text: "اضافة طلب", link: "/operations-maintenance" }, 
        { id: 2, image: img2, text: "الطلبات المؤرشفة", link: "/requests/Archived/Operations-Maintenance" },
        { id: 3, image: img3, text: "الطلبات المكتملة", link: "/requests/Completed/Operations-Maintenance" }, 
      ];
      title = "العمليات والصيانة"; 
      break;
    case "Projects" :
      cards = [
        { id: 1, image: img1, text: "اضافة طلب", link: "/Request-projects" }, 
        { id: 2, image: img2, text: "الطلبات المؤرشفة", link: "/requests/Archived/Projects" },
        { id: 3, image: img3, text: "الطلبات المكتملة", link: "/requests/Completed/Projects" }, 
      ];
      title = "المشاريع"; 
      break;
  }

  return (
    <div className="subscribers-container" dir="rtl">
      <h2 className="title">{title} </h2> 
      <p className="description">
        لبدء مشروعك، نحتاج إلى تخصيص تفضيلاتك.
      </p>
      
      <div className="cards-container">
        {cards.map((card) => (
          <Link to={card.link} key={card.id} className="card-link"> 
            <div className="card">
              <img src={card.image} alt={card.text} className="card-image" />
              <p className="card-text">{card.text}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Subscribers;
