import React from 'react';
import './Project.css';
import { Link } from 'react-router-dom';


const ProjectList = ({ branch, projects }) => {
    return (
        <div className="project-list" id="Project-List-BarChart">
            <h3>مشاريع اليوم فرع  {branch}</h3>
            <div className="project-container">
                {projects.map((project, index) => (
                    <Link to = {`/project/${project.id}`} className="project-card" key={index}>
                        {project.isNetworkImplemented == true ?<p>تم تنفيذ الشبكه</p> : <p>لم تنفيذ الشبكه</p> }
                        <p className='numberOrder'>رقم الطلب: {project.orderNumber}</p>
                        <p>{project.userName}</p>
                        <div className="project-image">
                            <img src={project.image} alt={project.userName} />
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ProjectList;
