import React, { useState, useEffect, useMemo } from "react";
import * as XLSX from "xlsx";
import img from "../../Image/microsoft-excel-icon 1.png";
import noDataImage from "../../Image/App Illustrations.jpg";
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import "./SearchRequests.css";
import axios from 'axios';
import { Url } from "../../function/FunctionApi";
import { getCookie } from "../../Pages/Login/Login";

const RequestCard = ({ request, isChecked, onChange }) => (
    <div className="request-card">
        <div className="checkbox-container">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                className="custom-checkbox"
            />
        </div>
        <p>الفرع: {request.branchName}</p>
        <p>رقم الطلب: {request.orderNumber}</p>
        <p>مقدم الطلب: {request.contractor}</p>
        <p>نوع المشروع: {request.projectType}</p>
        <p>
            حالة الطلب: {
                request.isArchive !== undefined 
                    ? (request.isArchive ? "مؤرشف" : "غير مؤرشف") 
                    : (request.isArchived !== undefined ? (request.isArchived ? "مؤرشف" : "غير مؤرشف") : "غير محدد")
            }
        </p> 
    </div>
);

const Filter = ({ name, value, onChange, options }) => (
    <div className="filter">
        {name === "requestNumber" ? (
            <input
                type="number"
                name={name}
                placeholder="رقم الطلب"
                value={value}
                onChange={onChange}
            />
        ) : name === "contractor" ? ( 
            <input
                type="text"
                name={name}
                placeholder="مقدم الطلب"
                value={value}
                onChange={onChange}
            />
        ) : (
            <select name={name} value={value} onChange={onChange}>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        )}
    </div>
);

const Modal = ({ isOpen, onClose, onExport }) => (
    isOpen && (
        <div className="modal-open">
            <div className="modal-content">
                <h2>تصدير البيانات</h2>
                <p>هل أنت متأكد أنك تريد تصدير الطلبات المحددة إلى Excel؟</p>
                <button className="export-button" onClick={onExport}>
                    تصدير
                </button>
                <button className="close-modal-button" onClick={onClose}>
                    إغلاق
                </button>
            </div>
        </div>
    )
);

const SearchRequests = () => {
    const [filters, setFilters] = useState({
        branch: "",
        requestNumber: "",
        contractor: "",
        projectType: "",
        requestStatus: "",
        searchQuery: ""
    });
    const [apiData, setApiData] = useState({
        ordersSubs: [],
        newProjects: [],
        operationOrders: []
    });
    const [selectedRequests, setSelectedRequests] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const userCookie = getCookie("user");

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = JSON.parse(userCookie).token;
                const response = await axios.get(`${Url}Admin/AllOrders`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setApiData({
                    ordersSubs: response.data.ordersSubs || [],
                    newProjects: response.data.newProjects || [],
                    operationOrders: response.data.operationOrders || []
                });
            } catch (err) {
                setError("فشل في تحميل البيانات، يرجى المحاولة لاحقاً.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userCookie]);

    const allRequests = useMemo(() => {
        return [
            ...apiData.ordersSubs.map(request => ({ ...request, type: 'order' })),
            ...apiData.newProjects.map(project => ({ ...project, type: 'project' })),
            ...apiData.operationOrders.map(order => ({ ...order, type: 'operation' }))
        ];
    }, [apiData]);

    const filteredRequests = useMemo(() => {
        return allRequests.filter(request => {
            const { branch, requestNumber, contractor, projectType, requestStatus, searchQuery } = filters;

            const matchesBranch = !branch || request.branchName?.toLowerCase() === branch.toLowerCase();
            const matchesRequestNumber = !requestNumber || request.orderNumber?.toString().includes(requestNumber);
            const matchesContractor = !contractor || request.contractor?.toLowerCase().includes(contractor.toLowerCase());
            const matchesProjectType = !projectType || request.projectType?.toString() === projectType;
            const matchesRequestStatus = !requestStatus || 
            (request.isArchive !== undefined && request.isArchive.toString() === requestStatus) || 
            (request.isArchived !== undefined && request.isArchived.toString() === requestStatus);
            const matchesSearchQuery = !searchQuery || Object.values(request).some(value =>
                value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );

            return matchesBranch && matchesRequestNumber && matchesContractor && matchesProjectType && matchesRequestStatus && matchesSearchQuery;
        });
    }, [allRequests, filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
    };

    const handleCheckboxChange = (requestId) => {
        setSelectedRequests(prevSelected => 
            prevSelected.includes(requestId)
                ? prevSelected.filter(id => id !== requestId)
                : [...prevSelected, requestId]
        );
    };

    const handleSelectAll = () => {
        setSelectedRequests(selectedRequests.length === filteredRequests.length ? [] : filteredRequests.map(request => request.id));
    };

    const exportToExcel = () => {
        const selectedData = allRequests.filter(request => selectedRequests.includes(request.id));
        const worksheet = XLSX.utils.json_to_sheet(selectedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Requests");
        XLSX.writeFile(workbook, "selected_requests.xlsx");
        setIsModalOpen(false);
    };

    if (loading) {
        return (
            <div>
                <Skeleton count={10} height={50} />
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const renderFilterOptions = (key) => {
        switch (key) {
            case "branch":
                return [
                    { value: "", label: "الفرع" },
                    { value: "الرياض", label: "الرياض" },
                    { value: "حائل", label: "حائل" }
                ];
            case "projectType":
                return [
                    { value: "", label: "نوع المشروع" },
                    { value: "ordersSubs", label: "مشتركين" },
                    { value: "operationOrders", label: "عمليات وصيانة" },
                    { value: "newProjects", label: "مشاريع" }
                ];
            case "requestStatus":
                return [
                    { value: "", label: "حالة الطلب" },
                    { value: "true", label: "مؤرشف" },
                    { value: "false", label: "غير مؤرشف" }
                ];
            default:
                return []; // حقل "مقدم الطلب" لم يعد يحتاج لخيارات
        }
    };

    return (
        <div className="search-requests-container">
            <div className="filters-container">
                <div className="select-all-checkbox">
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            checked={selectedRequests.length === filteredRequests.length && filteredRequests.length > 0}
                            onChange={handleSelectAll}
                            className="custom-checkbox"
                        />
                    </div>
                </div>

                {Object.keys(filters).map(key => (
                    key !== "searchQuery" && (
                        <Filter
                            key={key}
                            name={key}
                            value={filters[key]}
                            onChange={handleFilterChange}
                            options={renderFilterOptions(key)}
                        />
                    )
                ))}
            </div>

            <div className="cards-container">
                {filteredRequests.length > 0 ? (
                    filteredRequests.map((request, index) => (
                        <RequestCard
                            key={`${request.id}-${index}`}
                            request={request}
                            isChecked={selectedRequests.includes(request.id)}
                            onChange={() => handleCheckboxChange(request.id)}
                        />
                    ))
                ) : (
                    <div className="NotFoundProject" dir='rtl'>
                        <img src={noDataImage} alt="No Data" />
                        <p>لا توجد بيانات مطابقة</p>
                    </div>
                )}
            </div>

            {selectedRequests.length > 0 && (
                <div className="div-open-modal">
                    <button className="open-modal-button" onClick={() => setIsModalOpen(true)}>
                        تصدير <img src={img} alt="Export to Excel" />
                    </button>
                </div>
            )}

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onExport={exportToExcel} />
        </div>
    );
};

export default SearchRequests;
