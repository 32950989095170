import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../Image/image-removebg-preview (1) 1.png";
import NavDropdown from 'react-bootstrap/NavDropdown';
import "./Navbar.css";
import { Link, useNavigate } from 'react-router-dom';
import { setCookie, getCookie } from '../../Pages/Login/Login';

function NavBar({ userData }) {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        setCookie("user", "", -1);
        navigate("/login");
        setExpanded(false); // Close the navbar when logging out
    };

    // Close the Navbar when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.navbar')) {
                setExpanded(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Navbar expanded={expanded} expand="lg" className="bg-white" dir='rtl'>
            <div className='container'>
                <Container fluid>
                    <div className="user-info-dropdown DataUser">
                        <NavDropdown
                            title={
                                <h6 className="d-flex align-items-center ">
                                    <img className="userImage" src={userData ? userData.userImage : ""} alt="userImage" />
                                    <span>{userData && userData.displayName}</span>
                                </h6>
                            }
                            id="user-dropdown"
                        >
                            <NavDropdown.Item onClick={handleLogout}>تسجيل الخروج</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <Navbar.Toggle 
                        aria-controls="navbarScroll" 
                        className="toggle-icon" 
                        onClick={() => setExpanded(expanded ? false : "expanded")} 
                    />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="nav-links">
                            <Link to="/main-page" onClick={() => setExpanded(false)}>الرئيسية</Link>
                            <NavDropdown title="الاقسام" id="collapsible-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/Sub-page/Subscribers" onClick={() => setExpanded(false)}>المشتركين</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Sub-page/Operations-Maintenance" onClick={() => setExpanded(false)}>العمليات والصيانة</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Sub-page/Projects" onClick={() => setExpanded(false)} > المشاريع </NavDropdown.Item>
                            </NavDropdown>
                            <Link to="/projects" onClick={() => setExpanded(false)}> جميع المشاريع</Link>
                            <Link to="/contactus" onClick={() => setExpanded(false)}>تواصل معنا</Link>
                            <Link to="/about" onClick={() => setExpanded(false)}>من نحن</Link>
                        </Nav>
                        <Navbar.Brand href="#" className="d-flex align-items-center">
                            <Link to="/main-page" onClick={() => setExpanded(false)}><img src={logo} alt="Logo" className="logo-img" /></Link>
                        </Navbar.Brand>
                    </Navbar.Collapse>
                </Container>
            </div>
        </Navbar>
    );
}

export default NavBar;
