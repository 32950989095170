import { React, useEffect } from "react";
import Projects from "./Projects"


function MainPage({userData}) {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
            <Projects userData={userData}/>
        </>
    );
}

export default MainPage;
