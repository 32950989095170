import React from 'react';
import * as XLSX from 'xlsx'; 
import jsPDF from 'jspdf'; 
import "./Project.css";

const Form = ({ ApiData }) => {
    const {
        projectType,
        orderNumber,
        contractor,
        district,
        station,
        modelPhotos,
        sitePhotos,
        safetyWastePhotos,
        note,
        isExist,
    } = ApiData.data;

    const handleExportPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text(`رقم الطلب: ${orderNumber}`, 10, 10);
        doc.text(`المقاول: ${contractor}`, 10, 20);
        doc.text(`الحي: ${district}`, 10, 30);
        doc.text(`المحطة: ${station}`, 10, 40);
        doc.text(`ملاحظات: ${note}`, 10, 50);
        
        doc.save('order.pdf');
    };
    console.log(ApiData)

    const handleExportExcel = () => {
        const ws = XLSX.utils.json_to_sheet([{
            رقم_الطلب: orderNumber,
            المقاول: contractor,
            الحي: district,
            المحطة: station,
            ملاحظات: note,
        }]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'الطلب');
        XLSX.writeFile(wb, 'order.xlsx');
    };

    return (
        <div className="form-container" id="form-container" dir='rtl'>
            <div className='radio-container'>
                <h2>قم بتحديد نوع المشروع</h2>
                <div className="radio-group">
                    <label>
                        <input type="radio" value="network" checked={projectType === 0} readOnly />
                        <span className="radio-circle" />
                        طوارئ
                    </label>
                    <label>
                        <input type="radio" value="meter" checked={projectType === 1} readOnly />
                        <span className="radio-circle" />
                        احلال
                    </label>
                    <label>
                        <input type="radio" value="reinforcement" checked={projectType === 2} readOnly />
                        <span className="radio-circle" />
                        التعزيز
                    </label>
                    <label>
                        <input type="radio" value="MediumVoltage" checked={projectType === 3} readOnly />
                        <span className="radio-circle" />
                        الجهد المتوسط
                    </label>
                </div>
            </div>

            <div className='FormData'>
                <div className='container'>
                    <h3>بيانات الطلب</h3>
                    <p>Quote is generated upon loading the form</p>
                    <div className="input-group">
                        <input type="text" value={orderNumber} readOnly />
                        <input type="text" value={contractor} readOnly />
                    </div>
                    <div className="input-group">
                        <input type="text" value={district} readOnly />
                        <input type="text" value={station} readOnly />
                    </div>
                    <div className="input-group">
                        <input type="text" value={contractor} readOnly />
                    </div>
                    <div className="upload-section">
                        <h4>النموزج</h4>
                        <div className="file-list">
                            {modelPhotos.map((photo, index) => (
                                <div key={index} className="file-item">
                                    <img src={photo.url} alt={`Model ${index + 1}`} style={{ width: '50px', height: '50px' }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="upload-section">
                        <h4>صور الموقع</h4>
                        <div className="file-list">
                            {sitePhotos.map((photo, index) => (
                                <div key={index} className="file-item">
                                    <img src={photo.url} alt={`Site ${index + 1}`} style={{ width: '50px', height: '50px' }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="upload-section">
                        <h4>مخالفات السلامة</h4>
                        {isExist ? (
                            <div className="file-list">
                                {safetyWastePhotos.map((photo, index) => (
                                    <div key={index} className="file-item">
                                        <img src={photo.url} alt={`Safety ${index + 1}`} style={{ width: '50px', height: '50px' }} />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>لا توجد مخالفات سلامة.</p>
                        )}
                    </div>

                    <div className="input-group">
                        <textarea value={note} readOnly />
                    </div>

                    <div className="button-group">
                        <button type="button" className="export-button" onClick={handleExportPDF} style={{ background: 'rgba(76, 175, 79, 1)', color: 'white' }}>
                            تصدير PDF
                        </button>
                        <button type="button" className="export-button" onClick={handleExportExcel} style={{ background: 'rgba(76, 175, 79, 1)', color: 'white' }}>
                            تصدير Excel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Form;
