import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Footer from "./Component/Footer/Footer";
import NotFound from "./Pages/NotFound/NotFound";
import { getCookie } from "./Pages/Login/Login"; 
import Navbar from "./Component/NavBar/Navbar"; 
import "./assets/css/all.css";
import DashBoard from "./Pages/Dashoard/Dashboard";
import SearchRequests from "./Pages/SearchRequests/SearchRequestsPage"; 
import Orders from "./Pages/Orders/OrderPage"; 
import Project from "./Pages/Project/ProjectPage"; 
import AddAccount from "./Pages/AddAcount/AddAcountPage"; 
import Accounts from "./Pages/Accounts/AccountsPage";
import Engineers from "./Pages/Engineers/EngineersPage"; 
import MainPage from "./Pages/Main-Page/Main-Page"; 
import Subscribers from "./Pages/Subscribers/Subscribers"; 
import SubmitApplication from "./Pages/SubmitApplication/SubmitApplication"; 
import ArchivedRequests from "./Pages/ArchivedRequests/ArchivedRequests"; 
import OperationsMaintenance from "./Pages/OperationsMaintenance/OperationsMaintenance"; 
import NewRequest from "./Pages/NewRequest/NewRequest"; 
import Contactus from "./Pages/Contactus/Contactus"; 
import About from "./Pages/About/About"; 
import AllProject from "./Pages/AllProject/AllProject"; 

function App() {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true); 
    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const userCookie = getCookie("user");
        if (userCookie) {
            try {
                setUserData(JSON.parse(userCookie));
            } catch (e) {
                console.error("Error parsing user cookie:", e);
            }
        }
        setIsLoading(false); 
    }, []);

    useEffect(() => {
        const cookieValue = getCookie("user"); 
        if (!cookieValue && location.pathname !== "/") {
            navigate("/"); 
        }
    }, [navigate, location.pathname]);

    const showNavbarAndFooter =
        userData && userData.role === "eng" &&
        (
            location.pathname.toLowerCase().startsWith("/sub-page/") ||
            location.pathname.toLowerCase().startsWith("/requests/") ||
            [
                "/main-page",
                "/submit-application",
                "/submit-application/:id",
                "/operations-maintenance",
                "/operations-maintenance/:id",
                "/request-projects",
                "/request-projects/:id",
                "/contactus",
                "/about",
                "/projects"
            ].some(route => location.pathname.match(new RegExp(route.replace(':id', '[0-9]*'))))
        );

    return (
        <>
            {showNavbarAndFooter && <Navbar userData={userData} />} 
            <Routes>
                <Route path="/" element={<Login setUserData={setUserData} />} />

                {userData && userData.role === "admin" && (
                    <>
                        <Route path="/home-page" element={<DashBoard />} />
                        <Route path="/search-requests" element={<SearchRequests />} />
                        <Route path="/orders" element={<Orders />} />
                        <Route path="/project/:id" element={<Project />} />
                        <Route path="/add-account" element={<AddAccount />} />
                        <Route path="/add-account/:id" element={<AddAccount />} />
                        <Route path="/accounts" element={<Accounts />} />
                        <Route path="/engineers" element={<Engineers />} />
                    </>
                )}
                {userData && userData.role === "eng" && (
                    <>
                        <Route path="/main-page" element={<MainPage userData={userData} />} />
                        <Route path="/sub-page/:name" element={<Subscribers />} />
                        <Route path="/submit-application" element={<SubmitApplication userData={userData} />} />
                        <Route path="/submit-application/:id" element={<SubmitApplication userData={userData} />} />
                        <Route path="/requests/:namepage/:name" element={<ArchivedRequests userData={userData} />} />
                        <Route path="/operations-maintenance" element={<OperationsMaintenance userData={userData} />} />
                        <Route path="/operations-maintenance/:id" element={<OperationsMaintenance userData={userData} />} />
                        <Route path="/request-projects" element={<NewRequest userData={userData} />} />
                        <Route path="/request-projects/:id" element={<NewRequest userData={userData} />} />
                        <Route path="/contactus" element={<Contactus />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/projects" element={<AllProject userData={userData} />} />
                    </>
                )}
                <Route path="*" element={<NotFound />} />
            </Routes>
            {showNavbarAndFooter && <Footer />} 
        </>
    );
}

export default App;
