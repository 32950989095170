import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import UploadSection from './UploadSection';
import { Url } from "../../function/FunctionApi";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const createUrl = `${Url}OperationsAndMaintenaceRequest/create-or-update-order-operationAndMaintence`;
const updateUrl = `${Url}OperationsAndMaintenaceRequest/update`;

const Form = ({ userData, apiData }) => {
    const [formData, setFormData] = useState({
        ProjectType: '',
        FaultNumber: '',
        FaultType: '',
        District: '',
        Resources: '',
        Contractor: '',
        Consultant: '',
        Note: '',
        SafetyViolationsExist: ''
    });

    const [fileData, setFileData] = useState({
        ModelPhotos: [],
        SitePhotos: [],
        SafetyWastePhotos: [],
    });

    const fileInputRefs = {
        ModelPhotos: useRef(null),
        SitePhotos: useRef(null),
        SafetyWastePhotos: useRef(null),
    };

    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [loadingSave, setLoadingSave] = useState(false);
    const [loadingArchive, setLoadingArchive] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [error, setError] = useState('');
    const token = userData?.token;

    const projectTypeMapping = {
        'طوارئ': 1,
        'احلال': 2,
        'التعزيز': 3,
        'الجهد المتوسط': 4,
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'ProjectType') {
            setFormData((prev) => ({
                ...prev,
                ProjectType: projectTypeMapping[value]
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    useEffect(() => {
        if (apiData) {
            setFormData({
                ProjectType: apiData.projectType,
                FaultNumber: apiData.faultNumber || '',
                FaultType: apiData.faultType || '',
                Contractor: apiData.contractor || '',
                District: apiData.district || '',
                Resources: apiData.resources || '',
                Station: apiData.station || '',
                Consultant: apiData.consultant || '',
                Note: apiData.note || '',
                SafetyViolationsExist: apiData.isExist ? 'true' : 'false'
            });
            setFileData({
                ModelPhotos: apiData.modelPhotos || [],
                SitePhotos: apiData.sitePhotos || [],
                SafetyWastePhotos: apiData.safetyWastePhotos || [],
            });
        }
    }, [apiData]);

    const handleFileChange = (event, fileType) => {
        const uploadedFiles = Array.from(event.target.files);
        const newFiles = uploadedFiles.filter((file) => file.size <= 5 * 1024 * 1024);
        const rejectedFiles = uploadedFiles.filter((file) => file.size > 5 * 1024 * 1024);

        if (rejectedFiles.length) {
            setError('بعض الملفات كانت كبيرة جدًا ولم يتم تحميلها.');
        } else {
            setError('');
        }

        setFileData((prev) => ({
            ...prev,
            [fileType]: [...prev[fileType], ...newFiles],
        }));
    };

    const handleFileDelete = (fileType, fileName) => {
        setFileData((prev) => ({
            ...prev,
            [fileType]: prev[fileType].filter((file) => file.name !== fileName),
        }));
    };

    const openFileSelector = (fileType) => {
        fileInputRefs[fileType].current.click();
    };

    const handleSubmit = async (isArchive) => {
        if (isArchive) {
            setLoadingArchive(true);
        } else {
            setLoadingSave(true);
        }

        const submissionData = new FormData();
        const changedData = {};

        // جمع البيانات المتغيرة فقط
        Object.entries(formData).forEach(([key, value]) => {
            if (!apiData || value !== apiData[key]) {
                changedData[key] = value;
                submissionData.append(key, value);
            }
        });

        submissionData.append('isArchive', isArchive);

        Object.entries(fileData).forEach(([key, files]) => {
            files.forEach((file) => {
                submissionData.append(key, file);
            });
        });

        try {
            if (apiData) {
                // عند التحديث، استخدم PUT مع إرسال البيانات المتغيرة فقط
                submissionData.append('OrderNumber', apiData.faultNumber); // رقم الطلب مطلوب
                await axios.put(updateUrl, submissionData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                });
            } else {
                // عند الإنشاء، استخدم POST
                await axios.post(createUrl, submissionData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`,
                    },
                });
            }
            setShowSuccessModal(true);
        } catch (error) {
            setError(error.response?.data?.message || 'فشل في إرسال النموذج. يرجى المحاولة مرة أخرى.');
            setShowErrorModal(true);
        } finally {
            setLoadingSave(false);
            setLoadingArchive(false);
        }
    };

    return (
        <div className="form-container" dir='rtl'>
            <div className='radio-container'>
                <div className='container'>
                    <h2>قم بتحديد نوع المشروع</h2>
                    <div className="radio-group">
                        {Object.keys(projectTypeMapping).map((type) => (
                            <label key={type}>
                                <input
                                    type="radio"
                                    name="ProjectType"
                                    value={type}
                                    checked={formData.ProjectType === projectTypeMapping[type]}
                                    onChange={handleChange}
                                />
                                <span className="radio-circle" />
                                <span>{type}</span>
                            </label>
                        ))}
                    </div>
                </div>
            </div>

            <div className='FormData'>
                <div className='container'>
                    <h3>بيانات الطلب</h3>
                    <p>Get a Quote Immediately Upon Form Submission</p>
                    <div className="input-group">
                        <input
                            type="text"
                            name="FaultNumber"
                            placeholder="رقم العطل"
                            value={formData.FaultNumber}
                            onChange={handleChange}
                            required
                            readOnly={!!apiData} // منع تعديل رقم العطل عند التعديل
                        />
                        <input
                            type="text"
                            name="FaultType"
                            placeholder="نوع العطل"
                            value={formData.FaultType}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            name="District"
                            placeholder="الحي"
                            value={formData.District}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="Resources"
                            placeholder="المواد"
                            value={formData.Resources}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="text"
                            name="Contractor"
                            placeholder="المقاول"
                            value={formData.Contractor}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="Consultant"
                            placeholder="الاستشاري"
                            value={formData.Consultant}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <UploadSection
                        label="النموزج"
                        fileType="ModelPhotos"
                        buttonLabel="تصوير مستندات مع الختم"
                        multiple={true}
                        handleFileChange={handleFileChange}
                        openFileSelector={openFileSelector}
                        fileData={fileData}
                        handleFileDelete={handleFileDelete}
                        fileInputRef={fileInputRefs['ModelPhotos']}
                    />

                    <UploadSection
                        label="صور الموقع"
                        fileType="SitePhotos"
                        buttonLabel="صور الموقع"
                        multiple={true}
                        handleFileChange={handleFileChange}
                        openFileSelector={openFileSelector}
                        fileData={fileData}
                        handleFileDelete={handleFileDelete}
                        fileInputRef={fileInputRefs['SitePhotos']}
                    />

                    <div className="violations">
                        <h4>مخالفات السلامة</h4>
                        <div className="radio-group">
                            {["true", "false"].map((value) => (
                                <label key={value}>
                                    <input
                                        type="radio"
                                        name="SafetyViolationsExist"
                                        value={value}
                                        checked={formData.SafetyViolationsExist === value}
                                        onChange={handleChange}
                                    />
                                    <span className="radio-circle" />
                                    <span>{value === "true" ? "نعم" : "لا"}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    <textarea
                        name="Note"
                        placeholder="ملاحظات"
                        value={formData.Note}
                        onChange={handleChange}
                        rows="4"
                    ></textarea>

                    {error && <div className="error-message">{error}</div>}
                    <div className="button-group">
                        <button
                            className="submit-button"
                            style={{ background: 'rgba(76, 175, 79, 1)', color: '#fff' }}
                            onClick={() => handleSubmit(false)} disabled={loadingSave}>
                            {loadingSave ? 'جاري التحميل...' : 'إرسال'}
                        </button>

                        <button 
                         className="submit-button"
                         style={{ background: 'rgba(244, 67, 54, 1)', color: 'white' }}
                        onClick={() => handleSubmit(true)} disabled={loadingArchive}>
                            {loadingArchive ? 'جاري التحميل...' : 'أرشفة'}
                        </button>
                    </div>
                </div>
            </div>

            {/* Success Modal */}
            <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>نجاح</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>تم إرسال النموذج بنجاح!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSuccessModal(false)}>إغلاق</Button>
                </Modal.Footer>
            </Modal>

            {/* Error Modal */}
            <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>خطأ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowErrorModal(false)}>إغلاق</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Form;
