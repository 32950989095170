import { React, useState, useEffect } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import Header from "../../Component/Header/Header";
import Card from "../../Component/DashoardContant/Cards/Card";
import ProjectList from "../../Component/DashoardContant/Project/Project";
import BarChart from "../../Component/DashoardContant/BarChart/BarChart";
import "./Dashboard.css";
import { fetchDataWithRetries } from "../../function/FunctionApi";
import ProjectListSkeleton from "../../Component/DashoardContant/Project/Skeleton"
import noDataImage from '../../Image/App Illustrations.jpg';

function DashBoard() {
    const [ordersCountInRiyadhAndHail, setOrdersCountInRiyadhAndHail] = useState([]);
    const [statisticsOrders, setStatisticsOrders] = useState([]);
    const [riyadhProjects, setRiyadhProjects] = useState([]);
    const [hailProjects, setHailProjects] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await fetchDataWithRetries("Account/get-count-engineers-in-riyadh-and-hail", setOrdersCountInRiyadhAndHail);
                await fetchDataWithRetries("OrderForSubscribe/statistics-orders", setStatisticsOrders);
                await fetchDataWithRetries("OrderForSubscribe/get-orders-in-RiyadhOrHail?branchName=الرياض", setRiyadhProjects);
                await fetchDataWithRetries("OrderForSubscribe/get-orders-in-RiyadhOrHail?branchName=حائل", setHailProjects);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="apDiv dashBoard">
            <Sidebar />
            <div className="body_container" dir="rtl">
                <div className="Header">
                    <Header />
                </div>
                <Card ordersCountInRiyadhAndHail={ordersCountInRiyadhAndHail} statisticsOrders={statisticsOrders} />
                {riyadhProjects.length > 0 ? (

                    <>
                        {loading ? (
                            <div className="Projects">
                                <ProjectListSkeleton />
                                <ProjectListSkeleton />
                            </div>
                        ) : (
                            <div className="Projects">
                                <ProjectList branch="الرياض" projects={riyadhProjects.data} />
                                <ProjectList branch="حائل" projects={hailProjects.data} />
                            </div>
                        )}
                    </>
                ) :
                    (
                        <div className="Projects">
                            <div className="project-list" id="Project-List-BarChart">
                                <h3>مشاريع اليوم فرع  الرياض</h3>
                                <div className="NotFoundProject" dir='rtl'>
                                    <img src={noDataImage} alt="No data available" className="no-data-image" />
                                    <p>لا يوجد بيانات للعرض</p>
                                </div>
                            </div>
                            <div className="project-list" id="Project-List-BarChart">
                                <h3>مشاريع اليوم فرع  حائل</h3>
                                <div className="NotFoundProject" dir='rtl'>
                                    <img src={noDataImage} alt="No data available" className="no-data-image" />
                                    <p>لا يوجد بيانات للعرض</p>
                                </div>
                            </div>
                        </div>
                    )

                }
                <BarChart />
            </div>
        </div>
    );
}

export default DashBoard;
