import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import img from "../../../Image/Rectangle9.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';

function TheBest() {
    return (
        <div className="TheBest-container" dir='rtl'>
            <Row className="TheBest-content">
                <Col lg={4} md={12}>
                    <h2>الافضل فقط</h2>
                    <div className='theBest-text'>
                        <div>
                            <h5>البناء الصديق للبيئة</h5>
                            <p>في "رسم" للاستشارات الهندسية، نركز على ممارسات البناء المستدام باستخدام تقنيات مبتكرة ومواد صديقة للبيئة. هدفنا هو تقليل الأثر البيئي وتعزيز كفاءة استهلاك الطاقة لخلق مساحات عصرية وصحية للمجتمع.</p>
                        </div>
                        <FontAwesomeIcon icon={faAnglesLeft} />
                    </div>
                    <div className='theBest-text'>
                        <div>
                            <h5>احدث تقنيات الاصلاح</h5>
                            <p>نستخدم في "رسم" أحدث تقنيات الإصلاح لضمان كفاءة وجودة المشاريع. تعتمد تقنياتنا على أساليب مبتكرة في تقييم الأضرار وإعادة التأهيل باستخدام مواد متقدمة، مما يحقق نتائج سريعة ومستدامة. </p>
                        </div>
                        <FontAwesomeIcon icon={faAnglesLeft} />
                    </div>
                    <div className='theBest-text'>
                        <div>
                            <h5>إدارة البناء عالية الجودة</h5>
                            <p>في "رسم" للاستشارات الهندسية، نضمن تقديم إدارة بناء عالية الجودة من خلال التخطيط الدقيق والتنفيذ المتقن. نستخدم أفضل الممارسات وأحدث التقنيات لضمان تحقيق النتائج المرجوة، مع الالتزام بالمواعيد والجداول الزمنية المحددة. هدفنا هو تقديم مشاريع تتميز بالمتانة والجمال، تلبية لاحتياجات عملائنا وتوقعاتهم.</p>
                        </div>
                        <FontAwesomeIcon icon={faAnglesLeft} />
                    </div>
                </Col>
                <Col lg={8} md={12} className="text-content">
                    <img src={img} alt="About Us" className="TheBest-image" />
                    <div className="stats-cards">
                        <div className='cards-content'>
                            <Card className="stats-card completed-projects">
                                <Card.Body>
                                    <h2 className="stats-number">12</h2>
                                    <p className="stats-label">المشاريع المكتملة</p>
                                </Card.Body>
                            </Card>
                            <Card className="stats-card years-of-experience">
                                <Card.Body>
                                    <h2 className="stats-number">6</h2>
                                    <p className="stats-label">سنوات التأسيس</p>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='cards-content'>
                            <Card className="stats-card">
                                <Card.Body>
                                    <h2 className="stats-number">18</h2>
                                    <p className="stats-label">موظفو المكتب</p>
                                </Card.Body>
                            </Card>
                            <Card className="stats-card">
                                <Card.Body>
                                    <h2 className="stats-number">24</h2>
                                    <p className="stats-label">العمال الميدانيون</p>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default TheBest;
