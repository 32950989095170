import React, { useEffect, useState } from 'react';
import axios from 'axios';
import projectImage1 from '../../../Image/Rectangle 34.png';
import noDataImage from '../../../Image/App Illustrations.jpg';
import { Url } from "../../../function/FunctionApi";
import { Link } from 'react-router-dom';
import moment from 'moment-hijri';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Projects = ({ Name, Namepage, userData }) => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const apiUrlMap = {
          "Subscribers": "OrderForSubscribe/filter-orders",
          "Operations-Maintenance": "OperationsAndMaintenaceRequest/filter-orders",
          "Projects": "NewProject/filter-orders",
        };


        const apiUrl = apiUrlMap[Name];
        if (!apiUrl) throw new Error("Invalid Name parameter");

        const params = {
          isArchive: Namepage === "Archived",
          isCompleted: Namepage === "Completed",
        };

        const response = await axios.get(`${Url}${apiUrl}`, {
          params,
          headers: {
            Authorization: `Bearer ${userData.token}`, 
          },
        });

        const projectData = Array.isArray(response.data) ? response.data : response.data?.data || [];
        setProjects(projectData);
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [Name, Namepage, userData]);

  const renderSkeletonLoader = () => (
    <div className="cards-container">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
        <div key={index} className="project-card">
          <Skeleton height={200} width={250} />
          <div className="project-info">
            <h3 className="project-title"><Skeleton width={150} /></h3>
            <p className="order-number"><Skeleton width={100} /></p>
            <p className="project-date"><Skeleton width={80} /></p>
            <Skeleton height={30} width={120} />
          </div>
        </div>
      ))}
    </div>
  );

  if (loading) return <div className="latest-projects-container" dir='rtl'><div className='container'>{renderSkeletonLoader()}</div></div>;

  if (error) return <div className="error-message" dir='rtl'>حدث خطأ: {error}</div>;

  if (projects.length === 0) {
    return (
      <div className="NotFoundProject" dir='rtl'>
        <img src={noDataImage} alt="No data available" className="no-data-image" />
        <p>لا يوجد بيانات للعرض</p>
      </div>
    );
  }

  return (
    <div className="latest-projects-container" dir='rtl'>
      <div className='container'>
        <div className="cards-container">
          {projects.map((project) => (
            <div key={project.id} className="project-card">
              <img
                src={project.modelPhotos?.[0]?.url || projectImage1}
                alt={project.faultType || "Project Image"}
                className="project-image"
              />
              <div className="project-info">
                <h3 className="project-title">
                  {project.faultType || (project.projectType === 1 ? "تنفيذ شبكة" : "عداد")}
                </h3>
                <p className="order-number">رقم العطل: {project.faultNumber || project.orderNumber}</p>
                <p className="project-date">
                  {`${moment(project.orderDate).format('iDD/iMM/iYYYY هـ')} | ${new Date(project.orderDate).toLocaleDateString()}`}
                </p>
                {Name === "Subscribers" ? 
                  <Link to={`/submit-application/${project.id}`} className="view-project-button">
                    الاطلاع علي الطلب
                  </Link> : 
                  Name === "Operations-Maintenance" ? 
                    <Link to={`/operations-maintenance/${project.id}`} className="view-project-button">
                      الاطلاع علي الطلب
                    </Link> : 
                    <Link to={`/Request-projects/${project.id}`} className="view-project-button">
                      الاطلاع علي الطلب
                    </Link>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
